.loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  ul {
    max-width: 60em;
    opacity: 1;
    width: 100%;
    height: 100%;

    li {
      transition: none;
      height: 300px;
      display: flex;
      flex-direction: column;
    }
  }
}

.noHover {
  pointer-events: none;
}

.show-hints-button {
  position: fixed;
  left: 30px;
  top: 200px;
  z-index: 3; // Ensures the button stays on top
  background-color: var(--background-color);
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  .MuiIconButton-root {
    width: 60px;
    height: 60px;
  }

  svg {
    color: var(--accent-color); 
  }

  @media (max-width: 760px) {
    left: 20px;
    top: 100px;

    .MuiIconButton-root {
      width: 50px;
      height: 50px;
    }

    svg {
      font-size: 1.8rem; 
    }
  }
}

:root {
  // Light Theme Colors
  --background-color: #F2F4F8;
  --text-color: #193659;
  --border-color: #DADDE3;
  --input-background:  #ECEFF4;
  --accent-color: #0f5bac;
  --disabled-color: #9fa6b0;
  --hover-color: #8cb1d3;
  --error-color:  #ff4d4f;
  --nav-bar-1: #19365909;
  --nav-bar-2: #19365933; 
  --nav-bar-scrolled: #193659e1;
  --hover-input: #19365980; 
  --drawer-2: #19365909;
  --drawer-1: #19365933; 
}

[data-theme="dark"] {
  // Dark Theme Colors
  --background-color: #282828;
  --text-color: #cacaca;
  --border-color: #3f3f3f;
  --input-background: #212121;
  --accent-color: #57789b;
  --disabled-color: #757575;
  --hover-color: #3E517A;
  --error-color:  #ff4d4f;
  --nav-bar-1: #282f371f;
  --nav-bar-2: #19212b8e; 
  --nav-bar-scrolled: #cacacad5;
  --hover-input: #121212; 
  --drawer-1: #212121;
  --drawer-2: #30333b; 
}

:root, [data-theme="dark"], body {
  transition: background-color 0.8s ease, color 0.8s ease; 
}

body {
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

h1, h2, h3, h4 {
  color: var(--text-color);
}

h1 {
  font-size: 48px;
  margin-bottom: 0.4em;
  text-align: center;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 18px;
}

p {
  font-family: "Merriweather";
  font-size: 18px;
}

.hint-card-wrapper {
  position: fixed;
  left: 20px;
  top: 100px;
  z-index: 1000;
  width: 400px;
  height: 250px;

  .hint-card-animation {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(45deg, var(--border-color), var(--text-color));
    border-radius: 15px;
    filter: blur(13px);
    animation: glow 1.3s infinite alternate;
  }

  @keyframes glow {
    0% {
      opacity: 0.5;
      transform: scale(0.93);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .hint-card {
    background: var(--background-color);
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 250px;

		.hint-card__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .hint-card__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .hint-title {
      display: flex;
      align-items: center;
      font-weight: 600;
      color: var(--text-color);

      .hint-icon {
        color: var(--accent-color);
        margin-right: 8px;
      }
    }

    .hint-description {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 10px;
      font-size: 1.1rem;
      line-height: 1.5;
      color: var(--text-color);
      height: 100%;
    }

    .hint-card__controls {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .small-hint-button {
        border-radius: 15px;
        padding: 5px 15px;
        font-size: 0.8rem;
        background: linear-gradient(135deg, var(--text-color), var(--disabled-color));
        color: var(--background-color);
        text-transform: none;
        transition: background 0.3s;
        box-shadow: none;

        &:hover {
          background: linear-gradient(135deg, var(--accent-color), var(--text-color));
        }

        &:disabled {
          background: #ccc;
          color: var(--disabled-color);
        }

        svg {
          font-size: 18px; /* Smaller icon size */
        }
    }
	}
  }
}

@media (max-width: 760px) {
  .hint-card-wrapper {
    width: 80%;
    left: 5%;
    top: 80px;
    height: 260px;

    .hint-card {
      height: 260px;
    }

    .hint-title {
      font-size: 1rem;
    }

    .hint-description {
      font-size: 0.9rem;
    }

    .hint-card__controls {
      .small-hint-button {
        font-size: 0.7rem; // Smaller buttons for mobile
        padding: 3px 8px;
        min-width: 60px;
        height: 28px;
      }
    }
  }
}

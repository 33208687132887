.wrapper {
  display: inline-flex;
  height: 45px;
  line-height: 45px;
  width: 70%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 3em;
  margin-bottom: 1.5em;
  z-index: 1;
}

.wrapper .option {
  background: var(--background-color);
  min-width: 180px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid var(--border-color);
  transition: all 0.3s ease;
  

  @media screen and (max-width: 760px) {
    width: 180px;
  }
}

.wrapper .option:hover {
  background-color: var(--input-background);
  box-shadow: 0px 0px 15px var(--background-color);
}

.wrapper .option .dot {
  height: 18px;
  width: 18px;
  background: var(--border-color);
  border-radius: 50%;
  position: relative;
  margin: 0px 15px;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  background: var(--accent-color);
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3 {
  border-color: var(--text-color);
  background: var(--text-color);
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot,
#option-3:checked:checked ~ .option-3 .dot {
  background: var(--input-background);
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before,
#option-3:checked:checked ~ .option-3 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 18px;
  color: var(--text-color);
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span,
#option-3:checked:checked ~ .option-3 span {
  color: var(--input-background);
}

@media (max-width: 760px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 25%;
    width: 90%;
    margin-top: 2.3em;
  }

  .wrapper .option {
    justify-content: flex-start;
    margin-top: 15px;
  }

  .wrapper .option .dot {
    margin-right: 15px;
  }
}

.home-container {
	background-color: var(--background-color);
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/44.png'); /* Update the path */
  background-size: cover;
  background-position: center;
	overflow: hidden;
	transition: background-color 0.8s ease, color 0.8s ease;
}

.home-content {
	display: flex;
  justify-content: center;
  align-items: center;
	flex-direction: column;
  padding: 20px;
	transition: background-color 0.8s ease, color 0.8s ease;
}

@media screen and (max-width: 793px) {
  .home-container {
		padding: 0px;
  }
}

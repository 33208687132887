body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

p {
  line-height: 1.5em;
}

h1, h2, h3, h4 {
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);
}

button {
  cursor: pointer;
}

@media (max-width: 760px), (hover: none) and (pointer: coarse) {
  .contacts {
    position: absolute;
    height: 3.4em;
  }

  .navbar-buttons {
    display: none;
  }
}
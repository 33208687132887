.read-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  min-height: 100vh;
  background: var(--background-color);
  background-image: url('../../assets/44.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  transition: background-color 0.8s ease, color 0.8s ease;
}

.read__container {
  width: 100%;
  max-width: 1200px;
  background-color: var(--background-color);
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin-top: 4em;
  transition: background-color 0.8s ease, color 0.8s ease;
}

.read-message-title {
  font-size: 2rem;
  margin-bottom: 1.2em;
  text-align: center;
  display: flex;
  justify-content: center;  
  color: var(--text-color); /* Adjust as needed */
}

.loading {
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-color);
}

.error {
  text-align: center;
  font-size: 1.2rem;
  color: var(--error-color);
  margin: 1em 0;
}


ul {
  list-style-type: none;
  padding: 0;

  li {
    padding: 0.5em 10px;
    text-align: justify;

    .index {
      font-weight: bold;
      margin-right: 0.5em;
      color: var(--text-color); /* Adjust as needed */
    }

    p {
      margin: 0;
      color: var(--text-color);
    }

    &.highlight {
      background-color: var(--border-color); /* Adjust highlight color as needed */
      color: var(--text-color);
    }
  }
}

/* Scroll-to-top button styles */
.read__container {
  position: relative;
}

/* Responsive Design */
@media (max-width: 600px) {
  .read__container {
    padding: 1em;
  }

  .message-title {
    font-size: 1.2rem;
  }

  li {
    padding: 0.3em 0;

    .index {
      margin-right: 0.3em;
    }
  }
}

.contacts {
  background-color: var(--background-color);
	display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
	width: 100%;
  bottom: 0em;
  padding: 10px;
	
}
$input-height: 3em;

.search-container {
  background-color: var(--background-color);
  transform-origin: left center;
  min-height: 100vh;
  height: auto; /* Allow height to adjust based on content */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: flex-start; /* Align items to the top */
  position: relative;
  background-image: url('../../assets/44.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  padding-top: 30px;
  transition: background-color 0.8s ease, color 0.8s ease;
  overflow-y: auto; /* Enable scrolling if needed */
}

.search {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    padding: 0 20px;
    margin-top: clamp(20px, 4vmin, 5vmin);
    width: 100%;

    @media only screen and (min-width: 793px) {
      width: 90%;
      justify-content: flex-start; /* Align items to start */
    }

    ul {
      list-style-type: none;
      padding-inline-start: unset;
      opacity: 0;
      max-width: 60em;

      li {
        margin: 2.5em 15px;
        padding: 1em 1.3em;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        border-radius: 10px;
        background-color: var(--input-background);
        color: var(--text-color);

        &:first-child {
          margin-top: 1.5em;
        }

        &:last-child {
          margin-bottom: 5em;
        }

        &:hover {
          cursor: pointer;
          animation-name: example;
          animation-duration: 0.3s;
          box-shadow: 0 0px 20px var(--text-color),
                      0 0px 15px var(--background-color);
        }
      }

      p {
        &:not(:first-child) {
          margin-top: 1em;
        }
        color: var(--text-color);
      }

      .underline {
        width: 4em;
        margin-top: 10px; 
        border-bottom: 4px solid var(--disabled-color);
      }
    }
  }
}

.no-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: 1.5em;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  max-width: 800px;
  margin-bottom: 5em;

  @media only screen and (max-width: 793px) {
    padding: 2rem;
  }

  @media only screen and (min-width: 2000px) {
    width: 100%;
    max-width: 1200px;
  }
}

.no-results-message {
  text-align: center;
  color: var(--text-color);

  & h2 {
    font-size: clamp(1.5rem, 3vmin, 3rem);
    color: var(--text-color);
  }

  & p {
    font-size: clamp(0.8rem, 1.6vmin, 1.5rem) !important;
    margin-top: 1em;
    color: var(--text-color);
    font-family: 'Roboto', sans-serif;
  }
}

.search-tips {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;

  & li {
    font-size: clamp(0.8rem, 1.7vmin, 1.5rem);
    color: var(--text-color);
    margin-bottom: 1em;
    text-align: left;
    background-color: var(--input-background);
    padding: 1em;
  }
}

.help-link {
  margin-top: 2em;
  color: var(--text-color);

  & span {
    color: var(--accent-color);
    font-weight: 600;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
  }
}

.no-results-animation {
  width: 100%; 
  height: clamp(400px, 600px, 1200px);

  @media only screen and (max-width: 793px) {
    height: 350px;
  }
}

#search-input-label {
  color: var(--text-color);
}

#search-input {
  color: var(--text-color);
  font-weight: bold;
}

.copy-button {
  display: none;
}

.MuiButton-outlined {
  border: 3px solid var(--dark-color) !important;
}

.transition {
  opacity: 1 !important;
  transition: 0.7s ease-in;
  height: -webkit-fill-available;
}

.message-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  color: var(--text-color); 
}

.makeStyles-margin-2 {
  display: none !important;
}

.MuiFormGroup-row {
  flex-direction: column !important;
}

.number-of-results {
  text-align: left;
}

.MuiSelect-select.MuiSelect-select {
  display: flex;
  align-items: center;
}

.info-div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1em;

  h5 {
    font-size: 18px;
  }
}

.load-more-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

@media only screen and (min-width: 960px) {
  li:hover .copy-button {
    display: flex;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
}

@media only screen and (min-width: 793px) {
  .search {
    &__container {
      width: 90%;
      justify-content: center;

      &__title {
        flex-direction: row;

        &--align {
          text-align: left;
          align-self: center;
        }

        &__description {
          margin: 0;
        }
      }
      li {
        flex-direction: row;
        position: relative;

        &:hover .copy-button {
          display: flex;
          position: absolute;
          right: 20px;
          margin: 10px 0;
        }
      }
    }

    &__form {
      max-width: 63em;
      margin-top: 1em;
    }
  }

  .makeStyles-margin-2 {
    display: flex !important;
  }

  .MuiFormGroup-row {
    flex-direction: row !important;
  }

  .contacts {
    bottom: 0;
  }
}
